@import "bootstrap/scss/bootstrap";
@import "rsuite/dist/rsuite.min.css";
@import "./helpers";

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Thin.ttf");
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-SemiBold.ttf");
    font-weight: bolder;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-ExtraLight.ttf");
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Light.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: Poppins;
    src: url("../assets/fonts/Poppins-Regular.ttf");
    font-weight: 600;
}

html {
    overflow-y: auto !important;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Poppins;
    outline: none;
    outline-style: none;
    box-shadow: none;
}

button:focus {
    outline: none !important;
    outline-style: none !important;
    box-shadow: none !important;
}

#root {
    min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(188, 188, 188);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(147, 147, 147);
}
